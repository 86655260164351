/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~angular2-multiselect-dropdown/themes/default.theme';

.btn-primary{
    background-color: #52B1E1;
    border-color: #52B1E1 !important;
    outline: none;
    &:focus{
        background-color: #52B1E1;
    }
    &:hover{
        background-color: #52B1E1;
        opacity: .9;
    }
}

.navbar{
    background-color: #52B1E1;
}
